.w-30 {
  width: 30% !important;
}

.service-update-modal .w-30 {
  margin-right: 1.5% !important;
  margin-left: 1.5% !important;
}

.wiocc-btn {
  background-color: #20358c;
  border-color: #20358c;
  /* color: white; */
}

.wiocc-action-btn:not([class*="outline-"]) {
  font-family: "Montserrat-Medium";
  background-color: #20358c;
  color: white;
}

.wiocc-action-btn.btn-outline-primary {
  border-color: #20358c;
  background: white;
  color: #20358c;
}

.wiocc-action-btn:hover,
.wiocc-action-btn:active {
  /* background-color: #20368b; */
  background-color: #172a77;
  border-color: #20358c;
  /* color: #20358c; */
}

.text-wiocc {
  /* note:color not exact */
  color: #2a49c4;
}

.wiocc-link {
  color: #20358c;
  cursor: pointer;
  text-decoration: underline;
}

.text-decoration-none {
  text-decoration: none;
}

.wiocc-btn:hover {
  background-color: #172a77;
  border-color: #172a77;
}

.wiocc-outline-btn {
  border-color: #20368b !important;
  color: rgb(32, 53, 140);
}

.wiocc-outline-btn:hover {
  background-color: #172a77;
  color: white;
}

.log-on-site-btn {
  float: right;
}

.mui-connectivity-3wb1p2-MuiTypography-root,
.MuiTypography-root.MuiTypography-body1.mui-connectivity-1uwgr7b-MuiTypography-root {
  font-family: "Montserrat-Regular" !important;
}

.h-mui-table table tbody td.MuiTableCell-root {
  padding: 0.7em 8px 0.7em 2px !important;
  font-family: "Montserrat-Regular";
  font-size: 12px !important;
}
.h-mui-table.padding-left table thead th.MuiTableCell-root:first-child,
.h-mui-table.padding-left table tbody td.MuiTableCell-root:first-child,
.h-mui-table.supplier-tickets-report-table
  table
  tbody
  td.MuiTableCell-root:first-child {
  padding-left: 0.5em !important;
}

.h-mui-table.supplier-tickets-report-table
  table
  tbody
  td.MuiTableCell-root:nth-child(2) {
  padding-left: 0.5em !important;
  border-left: 1px solid #ddd;
}

.h-mui-table.table-md table tbody td.MuiTableCell-root {
  font-size: 13px !important;
}

.network_ticket_tabs .nav-item.nav-link {
  font-size: 0.85rem !important;
}

.wrap-word {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* css-3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}

.h-mui-table th.MuiTableCell-root.MuiTableCell-head {
  padding: 0 0.1em 0.1em 0 !important;
}

.h-mui-table th.MuiTableCell-root.MuiTableCell-head span button,
.h-mui-table th.MuiTableCell-root.MuiTableCell-head div {
  text-transform: uppercase;
  /* padding: 0.15em 0.1em; */
  font-size: 0.9em;
  font-weight: bold;
  font-family: "Montserrat-Regular";
}

.h-mui-table
  tr
  th.MuiTableCell-root.MuiTableCell-head:nth-child(1)
  span
  button {
  padding: 0.15em 1.2em 0.1em;
}

.select-filter > * {
  font-family: "Montserrat-Regular";
  font-size: 0.9em;
}

.MuiPaper-root.MuiPopover-paper {
  min-width: 20vw;
  height: fit-content;
  overflow: visible;
}

/*.MuiPopover-root.MuiModal-root:not(.nav-menu) .MuiPopover-paper {
	left: unset !important;
	right: 2.6%;
}*/

fieldset.MuiFormControl-root .MuiFormControlLabel-root {
  margin-left: 0;
}

table tfoot.MuiTableFooter-root td,
table tfoot.MuiTableFooter-root td p {
  padding: 0 !important;
}

.service-update-modal .form-group input {
  font-size: 0.9em;
  font-weight: normal;
}

.service-update-modal .form-group.row input {
  font-size: 0.9em;
  font-weight: normal !important;
}

.h-mui-table:not(.striped-none)
  table:not(.bom-table)
  tbody
  tr:nth-child(odd):not(.bg-white) {
  /* background-color: #e2efff !important; */
  background-color: #e2efff;
  /* border: 2px solid red !important; */
}

.h-mui-table .mui-1ex1afd-MuiTableCell-root.text-white {
  color: white !important;
}

.h-mui-table tr.text-white td {
  color: white !important;
  /* border: 5px solid red !important; */
}

.h-mui-table table tbody td.MuiTableCell-root.select-padding {
  padding: 0.5em 8px !important;
}

table.bom-table tbody tr:hover {
  background-color: #e2efff !important;
}

.capacity-table table.MuiTable-root thead th {
  z-index: 0;
}

.ol-powerloss-graphmodal.channels-list .container-fluid .h-mui-table {
  box-shadow: none;
  border-bottom: 0;
}

.MuiToolbar-root.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar p {
  margin: auto;
}

table tfoot .MuiToolbar-root.MuiTablePagination-toolbar p {
  margin: 0;
}

.w-33 {
  width: 33%;
}

.form-check input {
  margin-top: -0.01em;
}

.h-mui-table thead tr th button div div {
  text-align: left;
  color: #20358c;
  font-weight: bold;
  font-size: 1em !important;
}

.full-height {
  height: 100% !important;
}

.tabledivProgrammes .expandable .inner.nuk-grid {
  transition: all 0.1s linear;
}

.overflow-y,
.page-content {
  max-height: 100vh;
  overflow-y: auto;
  padding-bottom: 4em;
}

.table-container {
  margin-bottom: 4em;
}

.h-mui-table.shadow-none .css-11mde6h-MuiPaper-root {
  box-shadow: none;
}

.h-mui-table .spinner-border {
  border-width: 0.18em;
}

/*
*
TICKETS PAGE
**/
.ticketinfotabs ~ .tab-content {
  padding-bottom: 5em;
}

.netsuite-info-label {
  font-family: "Montserrat-Regular";
  font-size: 0.7rem;
  color: #575555;
  line-height: 1;
}

.netsuite-info-value .nested-value {
  font-family: "Montserrat-Medium";
  overflow-wrap: break-word !important;
  font-size: 0.7rem;
  color: #000000f8;
}

tr.MuiTableRow-root ~ tr.bg-white td div table .MuiTableRow-footer {
  background: white !important;
}

table .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader {
  z-index: unset !important;
}

.h-mui-table:not(.equiano-table) thead tr.MuiTableRow-head > * {
  z-index: 110 !important;
}

/* 
------------------
INSIGHTS
------------------ */
.insight-chart-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.insight-chart-wrapper > [class*="col-"] {
  margin-bottom: 0.5em;
}

.insight-chart-wrapper .user-select-none.svg-container {
  min-height: 40vh;
  height: auto;
  max-height: auto;
}

@media (max-width: 1000px) {
  .h-mui-table
    .tss-1qtl85h-MUIDataTableBodyCell-root
    .tss-1t2q2nr-MUIDataTableBodyCell-stackedHeader {
    display: none;
  }

  .h-mui-table tbody td,
  .h-mui-table tbody td * {
    width: unset;
    max-width: unset !important;
  }

  .log-on-site-btn {
    float: none;
  }

  .pop-subtabs .tab-content div.tab-pane {
    margin-bottom: 6.5rem;
    margin-top: 0.5rem;
  }
}

@media (min-width: 768px) {
  /* TABLE FILTERS */
  .MuiGrid-root.css-zcmli0-MuiGrid-root {
    width: 35vw;
    flex-direction: row;
  }
  .MuiGrid-root
    .MuiGrid-root.MuiGrid-item.tss-stfyet-MUIDataTableFilter-gridListTile {
    flex-wrap: auto;
    flex-basis: 50%;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2.tss-oeo5eo-MUIDataTableToolbar-filterPaper.MuiPopover-paper.css-ubpweo-MuiPaper-root-MuiPopover-paper {
    left: unset !important;
    right: 3%;
    height: auto;
    min-height: 60vh;
    max-height: 60vh;
    overflow-x: auto;
  }
}

@media (max-width: 768px) {
  .blank-page-content .page-content {
    padding: 0 0.5em;
    position: absolute;
    overflow: auto;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
  }

  .tss-1vd39vz-MUIDataTableBodyCell-stackedCommon {
    font-size: 12px !important;
  }
}

.service-documents > div {
  width: 45%;
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0;
}

.service-documents > div:not(:first-child) > div,
.service-documents > div:first-child > div > div:first-child {
  font-weight: 400;
  color: #20368b !important;
}
.programmes-table .tss-qbo1l6-MUIDataTableToolbar-actions > span {
  float: right;
}
