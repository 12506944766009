.form-fields {
  width: 100% !important;
}
.card {
  width: 100%;
  margin-top: 8px;
  border-top-right-radius: 16px !important;
  border-top-left-radius: 16px !important;
}
.css-nfs0y7-MuiTypography-root {
  color: rgb(32, 53, 140) !important;
  font-weight: bolder !important;
}
.new-ticket-dialog-body {
  background-color: transparent;
}
.card-header {
  height: 5px !important;
  background-color: rgb(224, 230, 239) !important;
  border-top-right-radius: 16px !important;
  border-top-left-radius: 16px !important;
}

.form-fields input:read-only {
  background-color: #fff;
}
.form-fields textarea:read-only {
  background-color: #fff;
}
.tabs {
  border-top-left-radius: "10px";
  border-top-right-radius: "10px";
  margin: "2px";
}
.right-align {
  float: right;
}
.MuiCardHeader-title {
  font-size: 1em !important;
  font-weight: bolder;
  font-variant: normal;
  color: white !important;
}
.ticket-card-header {
  height: 5px !important;
  border-top-right-radius: 16px !important;
  border-top-left-radius: 16px !important;
  color: white !important;
}
.ticket-card-header-ready {
  background-color: rgb(45, 72, 186) !important;
  font-size: small !important;
}
.css-1i7ug36-MuiListItem-root {
  font-weight: bold !important;
  color: black !important;
}
.css-1i7ug36-MuiListItem-root:hover {
  background-color: rgb(45, 72, 130) !important;
  color: white !important;
}
.css-i4bv87-MuiSvgIcon-root:hover {
  color: white !important;
}
.css-cveggr-MuiListItemIcon-root {
  width: 10px !important;
  min-width: 30px !important;
}
.ticket-card-header-init {
  background-color: rgb(45, 72, 186, 0.5) !important;
}

.action-btn-success {
  background-color: rgb(45, 72, 186, 0.9) !important;
  font-weight: bold;
}
.action-btn-success:hover {
  background-color: rgb(45, 72, 186) !important;
}
.new-ticket-btn {
  position: fixed !important;
  background-color: rgb(45, 72, 186) !important;
  font-weight: bolder;
  display: flex;
  justify-content: center;
}
.new-ticket-btn:hover {
  background-color: rgb(45, 72, 186, 0.9) !important;
}

.default-mui-style .MuiInputBase-input {
  height: auto !important;
}

.progress-dialog {
  display: flex;
  justify-content: center;
}

.MuiCardContent-root {
  width: 100% !important;
}
.dialog-card {
  background-color: #edeade !important;
}

.head-set-icon {
  color: white !important;
  font-size: 3.5em !important;
  font-weight: bolder;
}

#department {
  max-height: 30px !important;
}
.MuiPaper-root.MuiPopover-paper {
  max-height: 200px !important;
  overflow-y: auto !important;
}
