.scanner-nav-item {
	color: rgba(255, 255, 255, 0.5);
	padding: 0.5em;
}

.scanner-nav-item:hover {
	color: white;
}

.qr-code-container {
	margin: auto;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: Montserrat-Medium;
	font-size: 13px;
	text-align: center;
}

.scanner-nav-item .qr-code-container .qr-scanner-wrapper {
	height: 100vh !important;
	top: 0;
	right: 1%;
}

.scanner-nav-item .qr-code-container > div > span {
	font-size: 0.175em !important;
}

.qr-code-container span {
	cursor: pointer;
}
.qr-code-container span i {
	font-size: 10em;
}
